import { call, put, take, takeLatest } from 'redux-saga/effects';
import {
  loading,
  setChartSearchData,
  setConfig,
  setConfigList,
  setData,
  setNavigationData,
  setView,
  addToastData,
} from '../../actions';
import {
  ADD_RECORD,
  COPY_RECORD,
  GET_CONFIG,
  GET_CONFIGS,
  GET_DATA_BY_FIRMID,
  GET_RECORD,
  REMOVE_FILE,
  SAVE_DATA,
  UPLOAD_FILE,
  REMOVE_TOAST_DATA,
} from '../../constants';

import {
  addRecord,
  copyRecord,
  getConfig,
  getConfigs,
  getData,
  getRecord,
  removeFile,
  saveData,
  uploadFile,
} from '../../lib/api';

import { getViewType } from '../../lib/storage';

function* workerGetDataSaga(actions) {
  const data = yield call(
    getData,
    actions.payload.filters,
    actions.payload.tableFilters,
    actions.payload.sorting_data,
    actions.payload.collectionName,
    actions.payload.page_number,
    actions.payload.page_size
  );
  let dt = data.data;
  const { count } = data;
  if (!data.success) {
    dt = [];
    if (data.message) {
      yield put(addToastData(data.message, 'error'));
    }
  }
  if (actions.payload.isChartScreen) {
    yield put(setChartSearchData(dt, count));
  } else if (actions.payload.forNavigation) {
    yield put(setNavigationData(dt, count, data.next, data.prev));
  } else {
    yield put(setData(dt, count));
  }
  yield put(loading(false));
}

function* workerGetRecordSaga(actions) {
  const data = yield call(getRecord, actions.payload.value, actions.payload.collectionName);
  let dt = data.data;
  let found = true;
  if (!data.success) {
    dt = [];
    found = false;
    if (data.message) {
      yield put(addToastData(data.message, 'error'));
    }
  }
  if (data.redirect) {
    document.location = '/dashboard/';
  }
  yield put(setData(dt, 1, found));
  yield put(loading(false));
}

function* workerGetConfigSaga(actions) {
  try {
    const data = yield call(getConfig, actions.payload.collectionName);
    let dt = data.config;
    const { collectionName } = data;
    const { revision } = data;
    const is_detail_page = actions.payload.is_detail_page;
    yield put(setConfig(dt, data.schema, collectionName, revision, is_detail_page));
    yield put(setView(getViewType(collectionName)));

    if (!data.success) {
      dt = [];
      if (data.message) {
        yield put(addToastData(data.message, 'error'));
      }
    }
  } catch (e) {
    yield put(addToastData(`Get config error ${JSON.stringify(e)}`, 'error'));
    console.error('Get config error', JSON.stringify(e));
  }
  yield put(loading(false));
}

function* workerGetConfigsListSaga() {
  if (!localStorage.jwtToken) return;
  const data = yield call(getConfigs);
  let { configs, revision } = data;
  if (!data.success) {
    configs = [];
  }
  yield put(setConfigList(configs, revision));
  yield put(loading(false));
}

function* workerSaveDataSaga(actions) {
  const { recordId } = actions.data;
  const { collectionName } = actions.data;
  let new_errors = {};
  const da = yield call(saveData, actions.data, collectionName);
  if (da.not_updated_fields && da.not_updated_fields.length > 0) {
    yield put(addToastData(da.not_updated_fields.join(' '), 'warning'));
    yield take((action) => {
      return action.type === REMOVE_TOAST_DATA && action.payload;
    });
  }
  if (da.validator_errors) new_errors = da.validator_errors;
  if (da.success) {
    if (da.new_record && da.collectionName) {
      document.location = `/detail/${collectionName}/${da.new_record.RecordId}/0`;
    }
    if (da.warnings && da.warnings.length > 0) {
      yield put(addToastData(`Warning: ${da.warnings.join(' ')}`, 'warning'));
      yield take((action) => {
        return action.type === REMOVE_TOAST_DATA && action.payload;
      });
    }
    yield put(addToastData('Successfully updated!', 'success'));
  } else {
    if (da.error) yield put(addToastData(da.message || da.error, 'error'));
    if (da.warnings && da.warnings.length > 0) {
      yield put(addToastData(`Warning: ${da.warnings.join(' ')}`, 'warning'));
      yield take((action) => {
        return action.type === REMOVE_TOAST_DATA && action.payload;
      });
    }
  }

  const data = yield call(getRecord, recordId, collectionName);
  let dt = data.data;
  if (!data.success) {
    dt = [];
    if (data.message) {
      yield put(addToastData(data.message, 'error'));
    }
  }
  yield put(setData(dt));
  if (actions.data.callback) actions.data.callback(new_errors, da.saveChangedValues);
  yield put(loading(false));
}

function* workerCopyRecord(actions) {
  const { firmID } = actions.payload;
  const { recordID } = actions.payload;
  const { collectionName } = actions.payload;
  const { UserName } = actions.payload;

  const da = yield call(copyRecord, firmID, recordID, collectionName, UserName);
  if (da.success) {
    yield put(addToastData('Successfully copied! You will be moved into the new record.', 'success'));
    setTimeout(() => {
      const newRecordID = da.recordID;
      window.location = `/detail/${collectionName}/${newRecordID}/${firmID}`;
    }, 2000);
  } else {
    if (da.message) yield put(addToastData(da.message, 'error'));
    console.error(da.message);
  }
}

function* workerUploadFileSaga(actions) {
  const data = yield call(uploadFile, actions.data);
  const product = data.data;
  if (data.success) {
    yield put(setData(product));
  } else if (data.message) {
    yield put(addToastData(data.message, 'error'));
  }
  yield put(loading(false));
}

function* workerRemoveFileSaga(actions) {
  const data = yield call(removeFile, actions.data);
  const product = data.data;
  if (data.success) {
    yield put(setData(product));
  } else if (data.message) {
    yield put(addToastData(data.message, 'error'));
  }
  yield put(loading(false));
}

function* workerAddRecord(actions) {
  const { fields } = actions.payload;
  const { collectionName } = actions.payload;
  const { UserName } = actions.payload;

  const da = yield call(addRecord, fields, collectionName, UserName);
  if (da.success) {
    const message = `Successfully created! New Record ID = ${da.recordID}`;
    yield put(addToastData(message, 'success'));
    window.open(`/detail/${collectionName}/${da.recordID}/null`, '_blank');
  } else {
    if (da.error && da.message) yield put(addToastData(da.message, 'error'));
    console.error(da);
  }
}

export default function* watchGetUsersSaga() {
  yield takeLatest(GET_DATA_BY_FIRMID, workerGetDataSaga);
  yield takeLatest(GET_CONFIG, workerGetConfigSaga);
  yield takeLatest(GET_CONFIGS, workerGetConfigsListSaga);
  yield takeLatest(SAVE_DATA, workerSaveDataSaga);
  yield takeLatest(COPY_RECORD, workerCopyRecord);
  yield takeLatest(GET_RECORD, workerGetRecordSaga);
  yield takeLatest(UPLOAD_FILE, workerUploadFileSaga);
  yield takeLatest(ADD_RECORD, workerAddRecord);
  yield takeLatest(REMOVE_FILE, workerRemoveFileSaga);
}
