import { ADD_TOAST_DATA, REMOVE_TOAST_DATA } from '../constants';

const initialState = {
  toast: {
    message: '',
    severity: 'info',
    show: false,
  },
};

export default function setToastInfo(state = initialState, action) {
  switch (action.type) {
    case ADD_TOAST_DATA:
      return {
        ...state,
        toast: {
          show: true,
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };

    case REMOVE_TOAST_DATA:
      return {
        ...state,
        toast: { ...state.toast, show: false },
      };

    default:
      return state;
  }
}
