import setAuthToken from './setAuthToken';

export default function checkAuth(response, isAxios) {
  if (response.status === 401) {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
    window.location.reload();
  } else {
    return isAxios ? response : response.json();
  }
}
