import { useSelector } from 'react-redux';
import { keycloak } from '../utils/keycloak';

const useRequestHeaders = () => {
  const accessToken = useSelector((state) => state.authReducer.accessToken);
  const jwtToken = accessToken || keycloak.token;

  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-access-token': jwtToken,
    host: window.location.hostname,
  };
};

export default useRequestHeaders;
