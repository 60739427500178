import { DEFAULT_VIEW, PERSONALISED_VIEW, View } from '../screens/Dashboard/ViewSelector';

export function getViewType(collectionName: string): View {
  const savedView = localStorage.getItem('view:' + collectionName) as View;
  const fieldsFromUrl = new URLSearchParams(window.location.search).get('fields');
  const notPersonalised = fieldsFromUrl && savedView !== PERSONALISED_VIEW;
  return notPersonalised ? DEFAULT_VIEW : savedView || DEFAULT_VIEW;
}

export function saveColumnSizesToStorage(collectionName: string) {
  const storageKeyName = `${collectionName}:columns_sizes`;
  localStorage.setItem(storageKeyName, localStorage.getItem(storageKeyName) || '');
}

export function setViewToStorage(collectionName: string, view: View) {
  localStorage.setItem('view:' + collectionName, view as string);
}
