import Keycloak from 'keycloak-js';

export const keycloak = new Keycloak(process.env.REACT_APP_BACKEND_URL + '/keycloak.json');

export const initializeKeycloak = () =>
  keycloak.init({
    onLoad: 'login-required',
    pkceMethod: 'S256',
    checkLoginIframe: false,
  });
