import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import Header from '../../components/pagewrap/Header';

import checkAuthFromFetch from '../../utils/checkAuthFromFetch';
import { ROLES } from '../../constants';
import useRequestHeaders from '../../hooks/useRequestHeaders';
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
import useToast from '../../components/toastNotification/useToast';

export const Profile = (props) => {
  const [newUsers, setNewUsers] = useState([]);
  const [url, setUrl] = useState('');
  const toast = useToast();
  const headers = useRequestHeaders();

  const { user } = props;
  const userName = user.name || `${user.first_name} ${user.last_name}`;

  useEffect(() => {
    // TODO: Rethink this
    fetch(`${BACKEND_URL}/keycloak/change-password`)
      .then((response) => {
        return response.json();
      })
      .then((data) => setUrl(data.redirectUrl));

    if (user?.role === ROLES.Admin) loadNewUsers();
  }, []);

  async function loadNewUsers() {
    fetch(`${BACKEND_URL}/auth/new-users`, {
      method: 'GET',
      headers,
    })
      .then(checkAuthFromFetch)
      .then((data) => {
        if (data.users) setNewUsers(data.users);
      });
  }

  function giveAccess(email) {
    const data = {
      email,
      type: 1,
    };
    fetch(`${BACKEND_URL}/user-manager`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    })
      .then(checkAuthFromFetch)
      .then((response) => {
        loadNewUsers();
        if (response.success) toast.success('Successfuly updated');
        else if (response.not_found) {
          toast.error('User not found');
        } else toast.error('Operation failed');
      });
  }

  async function deleteUser(email) {
    const confirmed = await toast.confirm('Are you sure you want to delete this user?');
    if (!confirmed) return;
    fetch(`${BACKEND_URL}/auth/deleteUser`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ email: email }),
    })
      .then(checkAuthFromFetch)
      .then((data) => {
        if (data.success) toast.success('User was deleted');
        else toast.error('User deleting failed');
        loadNewUsers();
      });
  }

  return (
    <div>
      <Header />
      <div className="container" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
        <div style={{}}>
          <h2> User Profile </h2>
          <div>
            <span style={{ fontSize: '17px' }}>
              Email
              <span style={{ marginLeft: '15px' }}>{user.email}</span>
            </span>
          </div>
          <div>
            <span style={{ fontSize: '17px' }}>
              Name
              <span style={{ marginLeft: '15px' }}>{userName}</span>
            </span>
          </div>
          <div>
            <span style={{ fontSize: '17px' }}>
              Role
              <span style={{ marginLeft: '15px' }}>{user.role}</span>
            </span>
          </div>
          {user.location && (
            <div>
              <span style={{ fontSize: '17px' }}>
                Location
                <span style={{ marginLeft: '15px' }}>{user.location}</span>
              </span>
            </div>
          )}
          {user.Upwork_Id && (
            <div>
              <span style={{ fontSize: '17px' }}>
                Upwork_Id
                <span style={{ marginLeft: '15px' }}>{user.Upwork_Id}</span>
              </span>
            </div>
          )}
          {user.Upwork_Profile_Id && (
            <div>
              <span style={{ fontSize: '17px' }}>
                Upwork_Profile_Id
                <span style={{ marginLeft: '15px' }}>{user.Upwork_Profile_Id}</span>
              </span>
            </div>
          )}
          {newUsers.length > 0 && (
            <div style={{ fontSize: '16px', marginTop: '15px' }}>
              <div>New Users</div>
              <div>
                {newUsers.map((user) => (
                  <div key={user.UserId}>
                    <span style={{ display: 'inline-block', width: '250px', marginBottom: '10px' }}>
                      {user.FirstName} {user.LastName}
                    </span>
                    <span
                      style={{
                        display: 'inline-block',
                        width: '250px',
                        marginBottom: '10px',
                        marginRight: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      {user.RegisteredUserEmail}
                    </span>
                    <Button onClick={() => giveAccess(user.RegisteredUserEmail)}>Give Access</Button>
                    <Button className="red" onClick={() => deleteUser(user.RegisteredUserEmail)}>
                      Delete user
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div style={{ marginLeft: '110px' }}>
          <a href={url}>
            <Button>Change password</Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Profile;
