import React, { useState } from 'react';
import { Button, Input } from 'semantic-ui-react';
import checkAuthFromFetch from '../../utils/checkAuthFromFetch';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
import useToast from '../toastNotification/useToast';
import useRequestHeaders from '../../hooks/useRequestHeaders';

const Admin = () => {
  const [email, setEmail] = useState('');
  const toast = useToast();
  const headers = useRequestHeaders();

  function setAccess(email, type) {
    const data = {
      email,
      type,
    };
    fetch(`${BACKEND_URL}/user-manager`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    })
      .then(checkAuthFromFetch)
      .then((response) => {
        if (response.success) toast.success('Successfuly updated');
        else if (response.not_found) {
          toast.error('User not found');
        } else toast.error('Operation failed');
      });
  }
  return (
    <div style={{ margin: '20px' }}>
      <h2>Grant or revoke user access to app</h2>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        placeholder="User email"
        style={{ marginRight: '20px', width: '250px' }}
      />
      <Button onClick={() => setAccess(email, 1)}>Grant access</Button>
      <Button onClick={() => setAccess(email, -1)}>Revoke access</Button>
    </div>
  );
};

export default Admin;
