import React, { useEffect, useState } from 'react';
import useRequestHeaders from '../hooks/useRequestHeaders';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Logs = () => {
  const [logs, setLogs] = useState('');
  const headers = useRequestHeaders();

  useEffect(() => {
    loadLogs();
  }, []);

  async function loadLogs() {
    fetch(`${BACKEND_URL}/database/showLog`, {
      method: 'GET',
      headers,
    })
      .then((response) => response.text())
      .then((data) => {
        setLogs(data);
      });
  }

  return (
    <div>
      <div>
        <br />
        {logs.split('\n').map((t, i) => (
          <div className="log_row" key={i}>
            {t.replace(/"/g, '')}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Logs;
