const GET_DATA_BY_FIRMID = 'GET_DATA_BY_FIRMID';
const GET_CONFIG = 'GET_CONFIG';
const SET_DATA = 'SET_DATA';
const SET_NAV_DATA = 'SET_NAV_DATA';
const SET_CONFIG = 'SET_CONFIG';
const SET_CONFIG_LIST = 'SET_CONFIG_LIST';
const SAVE_DATA = 'SAVE_DATA';
const GET_ERROR_MESSAGES = 'GET_ERROR_MESSAGES';
const SET_FORM_ERROR_MESSAGE = 'SET_FORM_ERROR_MESSAGE';

const AUTH_SET_REGISTERD_USER = 'AUTH_SET_REGISTERD_USER';
const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER';

const ADD_SEARCH_FIELD = 'ADD_SEARCH_FIELD';
const DELETE_FIELD = 'DELETE_FIELD';
const GET_CONFIGS = 'GET_CONFIGS';

const COPY_RECORD = 'COPY_RECORD';
const ADD_RECORD = 'ADD_RECORD';
const GET_RECORD = 'GET_RECORD';
const UPLOAD_FILE = 'UPLOAD_FILE';
const SET_FILE = 'SET_FILE';
const REMOVE_FILE = 'REMOVE_FILE';
const SET_CHART_DATA = 'SET_CHART_DATA';
const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
const SET_COLUMNS = 'SET_COLUMNS';
const ADD_COLUMN = 'ADD_COLUMN';
const REMOVE_COLUMN = 'REMOVE_COLUMN';
const SET_VIEW = 'SET_VIEW';
const LOADING = 'LOADING';
const ADD_TOAST_DATA = 'ADD_TOAST_DATA';
const REMOVE_TOAST_DATA = 'REMOVE_TOAST_DATA';

const ROLES = {
  Admin: 'Admin',
  Manager: 'Manager',
  Internal: 'internal',
  External: 'external',
};

export {
  ROLES,
  GET_DATA_BY_FIRMID,
  SET_DATA,
  GET_CONFIG,
  SET_CONFIG,
  SAVE_DATA,
  SET_CHART_DATA,
  SET_NAV_DATA,
  AUTH_SET_REGISTERD_USER,
  AUTH_LOGOUT_USER,
  ADD_SEARCH_FIELD,
  DELETE_FIELD,
  COPY_RECORD,
  GET_CONFIGS,
  SET_CONFIG_LIST,
  GET_RECORD,
  UPLOAD_FILE,
  SET_FILE,
  ADD_RECORD,
  REMOVE_FILE,
  CHANGE_PASSWORD,
  GET_ERROR_MESSAGES,
  SET_FORM_ERROR_MESSAGE,
  SET_COLUMNS,
  ADD_COLUMN,
  REMOVE_COLUMN,
  SET_VIEW,
  LOADING,
  ADD_TOAST_DATA,
  REMOVE_TOAST_DATA,
};
