/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { logoutUser } from '../../actions';

import './Header.scss';
import checkAuthFromFetch from '../../utils/checkAuthFromFetch';

import { ROLES } from '../../constants';
import useRequestHeaders from '../../hooks/useRequestHeaders';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export default function Header() {
  const [newUsersCount, setNewUsersCount] = useState(0);

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);
  const user = useSelector((state) => state.authReducer.user);
  const headers = useRequestHeaders();

  useEffect(() => {
    if (user && user.role === ROLES.Admin) loadNewUsers();
  }, []);

  async function loadNewUsers() {
    fetch(`${BACKEND_URL}/auth/new-users?count=1`, {
      method: 'GET',
      headers,
    })
      .then(checkAuthFromFetch)
      .then((data) => {
        if (data.count) setNewUsersCount(data.count);
      });
  }

  const logOutClick = () => {
    dispatch(logoutUser());
  };

  const name = user.name || `${user.first_name} ${user.last_name}`;

  const isProduction = window.REACT_APP_PROD === '1';
  const env = isProduction ? '' : window.location.href.includes('staging.') ? 'staging' : 'development';

  const href = window.location.href;
  let url = '/dashboard';
  if (href.includes('/detail/')) {
    url = '/dashboard?config=' + window.location.pathname.split('/')[2];
  }
  if (href.includes('/dashboard?config') && localStorage.config_name) {
    url = '/dashboard?config=' + localStorage.config_name;
  }
  return (
    <div className={`main_header ${env}`}>
      <NavLink to={url} className="logo" id="logo_url" data-qa="logo" reloadDocument>
        <img src="/fxcbi_logo_final.svg" />
      </NavLink>
      <div className="envTitle">{env}</div>
      {isAuthenticated ? (
        <div className="menuLinkContainer">
          <NavLink to="/profile" data-qa="profile" className="menuText" reloadDocument>
            {' '}
            {name}
            {newUsersCount > 0 && '*'}
          </NavLink>
          {(user.role == 'Admin' || user.role == 'Manager') && (
            <NavLink to="/users" data-qa="users" className="menuLink">
              Users
            </NavLink>
          )}
          {user.role == 'Admin' && (
            <NavLink to="/admin" data-qa="admin" className="menuLink">
              Admin Panel
            </NavLink>
          )}
          <p data-qa="logout" className="menuLink" onClick={logOutClick}>
            Log Out
          </p>
        </div>
      ) : (
        <div className="menuLinkContainer">
          <NavLink to="/" data-qa="login">
            <p className="menuLink">Log In</p>
          </NavLink>
        </div>
      )}
    </div>
  );
}
