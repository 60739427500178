import jwt_decode from 'jwt-decode';
import checkAuthFromFetch from '../utils/checkAuthFromFetch';
import setAuthToken from '../utils/setAuthToken';
import { keycloak } from '../utils/keycloak';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'x-access-token': localStorage.jwtToken,
  host: window.location.hostname,
};

async function updateTokenInTheHeader() {
  if (keycloak.isTokenExpired()) await keycloak.updateToken();
  return {
    ...headers,
    'x-access-token': keycloak.token,
  };
}

// DB Management APIs
export async function getData(filters, tableFilters, sorting_data, collectionName, page_number, page_size) {
  return fetch(`${BACKEND_URL}/database/fetchDatabase`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({
      collectionName,
      filters: filters.concat(tableFilters),
      sorting_data,
      page_number,
      page_size,
    }),
  }).then(checkAuthFromFetch);
}

export async function getRecord(value, collectionName) {
  return fetch(`${BACKEND_URL}/database/getRecord`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({
      value,
      collectionName,
    }),
  }).then(checkAuthFromFetch);
}

export async function getConfig(collectionName) {
  const decoded = jwt_decode(localStorage.jwtToken);
  let config_query = `?name=${collectionName}`;
  if (collectionName === '')
    config_query = localStorage.getItem('config_name') ? `?name=${localStorage.getItem('config_name')}` : '';

  if (!headers['x-access-token']) headers['x-access-token'] = localStorage.jwtToken;

  return fetch(`${BACKEND_URL}/database/fetchConfig${config_query}&email=${decoded.email}`, {
    method: 'GET',
    headers: await updateTokenInTheHeader(),
    credentials: 'include',
  }).then(checkAuthFromFetch);
}

export async function getConfigs() {
  let token = localStorage.jwtToken;
  if (!headers['x-access-token']) headers['x-access-token'] = token;

  const decoded = jwt_decode(token);
  setAuthToken(token);
  return fetch(`${BACKEND_URL}/database/getConfigs?email=${decoded.email}`, {
    method: 'GET',
    headers: await updateTokenInTheHeader(),
    credentials: 'include',
  }).then(checkAuthFromFetch);
}

export async function setUserConfig(data, collectionName) {
  let token = localStorage.jwtToken;
  if (!headers['x-access-token']) headers['x-access-token'] = token;

  const decoded = jwt_decode(token);
  const { email } = decoded;
  setAuthToken(token);
  return fetch(`${BACKEND_URL}/database/setUserConfig`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    credentials: 'include',
    body: JSON.stringify({ data, collectionName, email }),
  }).then(checkAuthFromFetch);
}

export async function saveData(data) {
  return fetch(`${BACKEND_URL}/database/saveDatabase`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify(data),
  }).then(checkAuthFromFetch);
}

export async function getAudit(VersionId) {
  return fetch(`${BACKEND_URL}/audit/getAudit`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ VersionId }),
  }).then(checkAuthFromFetch);
}

export async function getAuditData(AuditId) {
  return fetch(`${BACKEND_URL}/audit/getAuditData`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({ AuditId }),
  }).then(checkAuthFromFetch);
}

export async function copyRecord(firmID, recordID, collectionName, UserName) {
  return fetch(`${BACKEND_URL}/database/copy`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({
      firmID,
      recordID,
      collectionName,
      UserName,
    }),
  }).then(checkAuthFromFetch);
}

export async function uploadFile(data) {
  await updateTokenInTheHeader();
  return fetch(`${BACKEND_URL}/database/uploadFile`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'x-access-token': keycloak.token,
    },
    body: data,
  }).then(checkAuthFromFetch);
}

export async function removeFile(data) {
  return fetch(`${BACKEND_URL}/database/removeFile`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify(data),
  }).then(checkAuthFromFetch);
}

export async function addRecord(fields, collectionName, UserName) {
  return fetch(`${BACKEND_URL}/database/addRecord`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({
      fields,
      collectionName,
      UserName,
    }),
  }).then(checkAuthFromFetch);
}

export async function saveFunction(data) {
  return fetch(`${BACKEND_URL}/database/saveFunction`, {
    method: 'POST',
    headers: await updateTokenInTheHeader(),
    body: JSON.stringify({
      data,
    }),
  }).then((response) => response.json());
}
