import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Alert, Button, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './toast.scss';

const TOAST_DURATION = 4000;

function useToast(vertical = 'top', horizontal = 'center') {
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info',
    resolve: null,
  });
  const isConfirm = toast.severity === 'confirm';
  const isWarning = toast.severity === 'warning';
  const showToast = (message, severity) => {
    if (severity === 'confirm' || severity === 'warning') {
      return new Promise((resolve) => {
        setToast({ open: true, message, severity, resolve });
      });
    } else {
      setToast({ open: true, message, severity });
    }
  };

  const hideToast = (confirmed) => {
    if (toast.resolve) {
      toast.resolve(confirmed);
      setToast((prev) => ({ ...prev, open: false, resolve: null }));
    } else {
      setToast((prev) => ({ ...prev, open: false }));
    }
  };

  const success = (message) => showToast(message, 'success');
  const error = (message) => showToast(message, 'error');
  const info = (message) => showToast(message, 'info');
  const warning = (message) => showToast(message, 'warning');
  const confirm = (message) => showToast(message, 'confirm');

  const ToastComponent = () => (
    <Snackbar
      open={toast.open}
      autoHideDuration={isConfirm || isWarning ? null : TOAST_DURATION}
      onClose={hideToast}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert
        data-qa="alert"
        className="toast"
        action={
          <>
            {(isConfirm || isWarning) && (
              <div className="alert">
                <Button
                  data-qa="confirm"
                  variant="contained"
                  className="btn btn-confirm"
                  size="small"
                  onClick={() => {
                    hideToast(true);
                  }}
                >
                  {isWarning ? 'Ok' : 'Confirm'}
                </Button>
                {!isWarning && (
                  <Button
                    data-qa="decline"
                    className="btn"
                    size="small"
                    onClick={() => {
                      hideToast(false);
                    }}
                  >
                    Decline
                  </Button>
                )}
              </div>
            )}
            {!isConfirm && !isWarning && (
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  hideToast(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </>
        }
        severity={isConfirm ? 'info' : toast.severity}
        onClose={hideToast}
      >
        <div data-qa="alert-message"> {toast.message} </div>
      </Alert>
    </Snackbar>
  );

  useEffect(() => {
    const el = document.createElement('div');
    document.body.appendChild(el);

    ReactDOM.render(<ToastComponent />, el);

    return () => {
      ReactDOM.unmountComponentAtNode(el);
      document.body.removeChild(el);
    };
  }, [toast]);

  return { success, error, info, warning, confirm, hideToast };
}

export default useToast;
