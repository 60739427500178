import React, { useEffect, useState, Suspense, lazy } from 'react';

import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import App from './App.jsx';
import Profile from './screens/Profile/index';
import store from './store';

import 'semantic-ui-css/semantic.min.css';
import './App.scss';

import { logoutUser, setRegisterdUser } from './actions';

import { initializeKeycloak, keycloak } from './utils/keycloak';

import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import Loader from './components/loader/Loader';
import { ROLES } from './constants';
import { BACKEND_URL } from './lib/api.js';
import ReduxToastListener from './components/toastNotification/ReduxToastListener';
import TableLoadingSpinner from './screens/Dashboard/TableLoadingSpinner';

const AdminApp = lazy(() => import('./admin/App/AdminApp.jsx'));

export default function AppRouter() {
  const [decoded, setDecoded] = useState(
    keycloak?.tokenParsed || (localStorage.jwtToken ? jwt_decode(localStorage.jwtToken) : null)
  );
  const [loading, setLoading] = useState(true);

  window.REACT_APP_PROD = window.location.href.includes('datavalidation.fxcintel.com') ? '1' : '0';

  const getUserRole = async (token) => {
    // we cant use useRequestHeaders hook because we dont have access to the store here
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': token,
      host: window.location.hostname,
    };

    return fetch(BACKEND_URL + '/token/role', {
      method: 'GET',
      headers,
    }).then((data) => data.json());
  };

  useEffect(() => {
    initializeKeycloak().then(async () => {
      if (!keycloak.authenticated) {
        window.location.reload();
      } else {
        localStorage.setItem('jwtToken', keycloak.token);
        setAuthToken(keycloak.token);
        const _decoded = keycloak.tokenParsed;
        _decoded.accessToken = keycloak.token;
        try {
          const { role } = await getUserRole(keycloak.token);
          if (!role) {
            window.alert('You are not authorized to access this application. Please contact your administrator.');
            keycloak.logout();
          }

          _decoded.role = role;
          if (_decoded.role === 'admin') _decoded.role = ROLES.Admin;
          if (_decoded.role === 'manager') _decoded.role = ROLES.Manager;
        } catch (err) {
          keycloak.logout();
        }

        store.dispatch(setRegisterdUser(_decoded));
        setDecoded(_decoded);

        // Check for expired token
        const currentTime = Date.now() / 1000; // to get in milliseconds
        if (_decoded.exp < currentTime) {
          store.dispatch(logoutUser());
        } else if (window.location.pathname === '/admin/login') {
          window.location.href = '/admin';
        }
      }

      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  return (
    <Router>
      <Suspense fallback={<TableLoadingSpinner />}>
        <Provider store={store}>
          <ReduxToastListener />
        </Provider>
        <Routes>
          {decoded?.role === ROLES.Admin && (
            <Route path="/admin/*" element={<AdminApp user={decoded} page="admin" />} />
          )}
          {(decoded?.role === ROLES.Admin || decoded?.role === ROLES.Manager) && (
            <Route path="/users/*" element={<AdminApp user={decoded} page="users" />} />
          )}
          <Route
            path="/profile"
            element={
              <Provider store={store}>
                <Profile user={decoded} />
              </Provider>
            }
          />
          <Route path="/*" element={<App decoded={decoded} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
