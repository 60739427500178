import { AUTH_SET_REGISTERD_USER, GET_ERROR_MESSAGES, SET_FORM_ERROR_MESSAGE } from '../constants';

const initialState = {
  isAuthenticated: false,
  accessToken: localStorage.getItem('jwtToken'),
  // Registered User Info
  user: {},
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isEmpty = require('is-empty');

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_REGISTERD_USER:
      return {
        ...state,
        user: action.data,
        isAuthenticated: !isEmpty(action.data),
        accessToken: action.data?.accessToken,
      };
    case GET_ERROR_MESSAGES:
      return {
        ...state,
        loginErrorMessages: action.payload.data,
      };

    case SET_FORM_ERROR_MESSAGE:
      return {
        ...state,
        formErrorMessage: action.payload,
      };

    default:
      return state;
  }
}
