import React from 'react';
import { MoonLoader } from 'react-spinners';

const loaderCSS = {
  display: 'block',
  margin: '0 auto',
  marginTop: '200px',
};

export const TableLoadingSpinner = ({ loading }: { loading: boolean }) => (
  <>
    {loading && (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          zIndex: 5000,
        }}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <MoonLoader css={loaderCSS} sizeUnit={'px'} size={100} color={'#A4DA2A'} loading={loading} />
      </div>
    )}
  </>
);
export default TableLoadingSpinner;
