import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

const initialState = {};
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware,
});

sagaMiddleware.run(sagas);

export default store;
