import React from 'react';
import { Button, Dropdown, DropdownItemProps } from 'semantic-ui-react';

export const PERSONALISED_VIEW = 'personalised';
export const DEFAULT_VIEW = 'default';

export type View = typeof PERSONALISED_VIEW | typeof DEFAULT_VIEW;
type ViewOption = DropdownItemProps & { key: View; value: View };

const viewOptions: ViewOption[] = [
  {
    key: DEFAULT_VIEW,
    text: 'Default view',
    value: DEFAULT_VIEW,
    'data-qa': DEFAULT_VIEW,
  },
  {
    key: PERSONALISED_VIEW,
    text: 'Personalised view',
    value: PERSONALISED_VIEW,
    'data-qa': PERSONALISED_VIEW,
  },
];

type VieSelectorProps = {
  view: View;
  changeView: (view: View) => void;
  onSaveUserView: () => void;
};

export const ViewSelector = ({ changeView, view, onSaveUserView }: VieSelectorProps) => {
  return (
    <div
      style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: '16px' }}
    >
      <span style={{ display: 'flex' }}>
        <Button color="teal" data-qa="set-config-field" onClick={onSaveUserView}>
          Save&nbsp;View
        </Button>
        &nbsp;
        <Dropdown
          data-qa="select-view"
          placeholder="Select view"
          fluid
          selection
          value={view}
          onChange={(_e, { value }) => changeView(value as View)}
          options={viewOptions}
        />
      </span>
    </div>
  );
};
export default ViewSelector;
