import {
  ADD_COLUMN,
  ADD_RECORD,
  ADD_SEARCH_FIELD,
  ADD_TOAST_DATA,
  AUTH_LOGOUT_USER,
  AUTH_SET_REGISTERD_USER,
  COPY_RECORD,
  DELETE_FIELD,
  GET_CONFIG,
  GET_CONFIGS,
  GET_DATA_BY_FIRMID,
  GET_ERROR_MESSAGES,
  GET_RECORD,
  LOADING,
  REMOVE_COLUMN,
  REMOVE_FILE,
  REMOVE_TOAST_DATA,
  SAVE_DATA,
  SET_CHART_DATA,
  SET_COLUMNS,
  SET_CONFIG,
  SET_CONFIG_LIST,
  SET_DATA,
  SET_FILE,
  SET_FORM_ERROR_MESSAGE,
  SET_NAV_DATA,
  SET_VIEW,
  UPLOAD_FILE,
} from '../constants';

import { keycloak } from '../utils/keycloak';
import setAuthToken from '../utils/setAuthToken';

export function setData(data, count, found = true) {
  return {
    type: SET_DATA,
    payload: { data, count, found },
  };
}

export function loading(isLoading) {
  return {
    type: LOADING,
    payload: isLoading,
  };
}

export function setChartSearchData(data, count) {
  return {
    type: SET_CHART_DATA,
    payload: { data, count },
  };
}
export function getErrorMessages(data) {
  return {
    type: GET_ERROR_MESSAGES,
    payload: { data },
  };
}

export function setFormErrorMessage(message) {
  return {
    type: SET_FORM_ERROR_MESSAGE,
    payload: message,
  };
}

export function setNavigationData(data, count, next, prev) {
  return {
    type: SET_NAV_DATA,
    payload: { data, count, next, prev },
  };
}

export function setConfig(config, schema, collectionName, revision, is_detail_page) {
  return {
    type: SET_CONFIG,
    payload: {
      config,
      schema,
      collectionName,
      revision,
      is_detail_page,
    },
  };
}

export function setConfigList(configs, revision) {
  return {
    type: SET_CONFIG_LIST,
    payload: { configs, revision },
  };
}

export function getConfig(collectionName, is_detail_page = true) {
  return {
    type: GET_CONFIG,
    payload: { collectionName, is_detail_page },
  };
}

export function getConfigs() {
  return {
    type: GET_CONFIGS,
  };
}

// TODO: rename properly
export function getDataByFirmID(
  filters,
  tableFilters,
  sorting_data,
  collectionName,
  page_number,
  page_size,
  isChartScreen = false,
  forNavigation = false
) {
  return {
    type: GET_DATA_BY_FIRMID,
    payload: {
      filters,
      tableFilters,
      sorting_data,
      collectionName,
      page_number,
      page_size,
      isChartScreen,
      forNavigation,
    },
  };
}

export function getRecord(value, collectionName) {
  return {
    type: GET_RECORD,
    payload: { value, collectionName },
  };
}

export function saveData(data) {
  return {
    type: SAVE_DATA,
    data,
  };
}

// Authentication
export function setRegisterdUser(data) {
  return {
    type: AUTH_SET_REGISTERD_USER,
    data,
  };
}

export function logoutUser() {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  keycloak.logout();
  const data = {};
  return {
    type: AUTH_LOGOUT_USER,
    data,
  };
}

export function addSearchField(fields) {
  return {
    type: ADD_SEARCH_FIELD,
    fields,
  };
}

export function deleteField(fields) {
  return {
    type: DELETE_FIELD,
    fields,
  };
}

export function setColumns(columns) {
  return {
    type: SET_COLUMNS,
    columns,
  };
}

export function addColumns(columns) {
  return {
    type: ADD_COLUMN,
    columns,
  };
}

export function removeColumns(columns) {
  return {
    type: REMOVE_COLUMN,
    columns,
  };
}

export function setView(view) {
  return {
    type: SET_VIEW,
    view,
  };
}

export function uploadFile(data) {
  return {
    type: UPLOAD_FILE,
    data,
  };
}

export function removeFile(data) {
  return {
    type: REMOVE_FILE,
    data,
  };
}

export function setFile(data) {
  return {
    type: SET_FILE,
    data,
  };
}

export function copyRecord(firmID, recordID, collectionName, UserName) {
  return {
    type: COPY_RECORD,
    payload: {
      firmID,
      recordID,
      collectionName,
      UserName,
    },
  };
}

export function addRecord(fields, collectionName, UserName) {
  return {
    type: ADD_RECORD,
    payload: { fields, collectionName, UserName },
  };
}

export function addToastData(message, severity) {
  return {
    type: ADD_TOAST_DATA,
    payload: { message, severity },
  };
}

export function removeToastData(confirmed) {
  return {
    type: REMOVE_TOAST_DATA,
    payload: confirmed,
  };
}
