import React, { Suspense, lazy } from 'react';
import { Provider, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

import Header from './components/pagewrap/Header';
import Admin from './components/user/Admin';
import Home from './screens/Home';

import ReduxToastListener from './components/toastNotification/ReduxToastListener';
import TableLoadingSpinner from './screens/Dashboard/TableLoadingSpinner';
import Logs from './screens/Logs';
import store from './store';

import 'semantic-ui-css/semantic.min.css';
import './App.scss';
import { ROLES } from './constants';

const Dashboard = lazy(() => import('./screens/Dashboard/Dashboard.tsx'));
const Detail = lazy(() => import('./screens/Detail'));

export default function App(props) {
  return (
    <Provider store={store}>
      <div>
        <ReduxToastListener />
        <Header />
        <Loader />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="detail/:collectionName/:RecordId/:firmId" element={<Detail />} />
          <Route path="detail/:collectionName/:RecordId" element={<Detail />} />
          <Route
            path="dashboard"
            element={
              <Suspense fallback={<TableLoadingSpinner />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route path="/user-manager" component={props.decoded.role === ROLES.Admin ? Admin : Home} />
          {props.decoded.role === ROLES.Admin && <Route path="/logs" component={Logs} />}
        </Routes>
      </div>
    </Provider>
  );
}

function Loader() {
  const isLoading = useSelector((state) => state.dataReducer.loading);

  if (isLoading)
    return (
      <div
        data-qa="loader"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: '0px',
          zIndex: 5000,
        }}
      >
        <MoonLoader sizeUnit={'px'} size={100} color={'#A4DA2A'} loading={isLoading} />
      </div>
    );

  return null;
}
