import { combineReducers } from 'redux';

import authReducer from './auth';
import dataReducer from './data';
import toastReducer from './toast';

export default combineReducers({
  dataReducer,
  authReducer,
  toastReducer,
});
