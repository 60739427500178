import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

const Home = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.authReducer.isAuthenticated);

  const gotoDashboard = () => {
    if (isAuthenticated) {
      navigate('/dashboard');
    } else {
      navigate('/auth-login');
    }
  };

  return (
    <div className="container">
      <h1> Welcome </h1>
      <Button data-qa="go-to-dashboard" className="green authBtn" onClick={gotoDashboard}>
        Go To Dashboard
      </Button>
    </div>
  );
};

export default Home;
