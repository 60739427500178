import React from 'react';
import { MoonLoader } from 'react-spinners';

export default function Loader({ isLoading }) {
  
  return isLoading ? (
    <div
      data-qa="loader"
      style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: '0px',
      zIndex: 5000,
      }}
    >
      <MoonLoader sizeUnit={'px'} size={100} color={'#A4DA2A'} loading={isLoading} />
    </div>
  ) : null;
}
