import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeToastData } from '../../actions';
import useToast from './useToast';

export default function ReduxToastListener() {
  const toast = useToast();
  const dispatch = useDispatch();
  const toastData = useSelector((state) => state.toastReducer.toast);

  useEffect(() => {
    const displayToast = async () => {
      if (toastData?.show) {
        const confirmed = await toast[toastData.severity](toastData.message);
        dispatch(removeToastData(confirmed));
      }
    };
    displayToast();
  }, [toastData]);

  return null;
}
