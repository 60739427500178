import {
  ADD_COLUMN,
  ADD_SEARCH_FIELD,
  DELETE_FIELD,
  LOADING,
  REMOVE_COLUMN,
  SET_CHART_DATA,
  SET_COLUMNS,
  SET_CONFIG,
  SET_CONFIG_LIST,
  SET_DATA,
  SET_FILE,
  SET_NAV_DATA,
  SET_VIEW,
} from '../constants';

const initialState = {
  loading: false,
  data: [],
  config: {},
  configs: [],
  collectionName: 'ProductData',
  dataChanged: false,
  fields: [],
  columns: {
    default: {},
    personalised: {},
  },
  view: 'default',
};

export default function setBrowserInfo(state = initialState, action) {
  switch (action.type) {
    case SET_FILE:
      return {
        ...state,
        data: action.data,
        dataChanged: !state.dataChanged,
      };
    case LOADING:
      return {
        ...state,
        loading: action.payload, // !state.loading,
      };
    case SET_DATA:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        found: action.payload.found,
        dataChanged: !state.dataChanged,
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chart_data: action.payload.data,
        count: action.payload.count,
        dataChanged: !state.dataChanged,
      };
    case SET_NAV_DATA:
      return {
        ...state,
        nav_data: action.payload.data,
        nav_data_count: action.payload.count,
        next: action.payload.next,
        prev: action.payload.prev,
      };
    case SET_CONFIG_LIST:
      // eslint-disable-next-line no-case-declarations
      const no_configs_found = action.payload.configs.length === 0;

      return {
        ...state,
        revision: action?.payload?.revision,
        configs: action.payload.configs.map((item) => item.CollectionRelevantFor),
        no_configs_found,
        dataChanged: !state.dataChanged,
      };
    case SET_CONFIG:
      localStorage.removeItem('config');
      localStorage.setItem('config', JSON.stringify(action.payload.config));

      // eslint-disable-next-line no-case-declarations
      const data = {
        ...state,
        config: action.payload.config,
        schema: action.payload.schema,
        collectionName: action.payload.collectionName,
        revision: action.payload.revision,
      };
      if (!action.payload.is_detail_page) data.data = [];
      return data;
    case ADD_SEARCH_FIELD: {
      const { fields } = action;
      if (!fields || !fields.length) return state;
      return { ...state, fields };
    }

    case SET_VIEW: {
      const { view } = action;
      return { ...state, view };
    }

    case SET_COLUMNS: {
      const { columns } = action;
      if (!columns || !state.collectionName) return state;
      return {
        ...state,
        columns: {
          ...state.columns,
          [state.view]: {
            ...state.columns[state.view],
            [state.collectionName]: columns,
          },
        },
      };
    }
    case ADD_COLUMN: {
      const { columns } = action;
      if (!columns || !columns.length || !state.collectionName) return state;
      return {
        ...state,
        columns: {
          ...state.columns,
          [state.view]: {
            ...state.columns[state.view],
            [state.collectionName]: [state.columns[state.view][state.collectionName] || [], columns].flat(9),
          },
        },
      };
    }
    case REMOVE_COLUMN: {
      const { columns } = action;
      if (!columns || !columns.length || !state.collectionName) return state;
      return {
        ...state,
        columns: {
          ...state.columns,
          [state.view]: {
            ...state.columns[state.view],
            [state.collectionName]: (state.columns[state.view][state.collectionName] || []).filter(
              (col) => !columns.includes(col)
            ),
          },
        },
      };
    }

    case DELETE_FIELD: {
      const { fields } = action;
      if (!fields || !fields.length) return state;
      return { ...state, fields };
    }

    default:
      return state;
  }
}
